
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import ExpandIcon from '../../../images/expand-icon.png';
import ChainIcon from '../../../images/chain-icon.png';
import { useCurrentIdo } from '../../../state/idos';
import moment from 'moment';

const statusMapping = {
    0: "Starting Soon",
    1: "Running",
    2: "Ended",
    3: "Ended"
};

function IdoStats({ address }) {
    const { ido } = useCurrentIdo(address);
    const [saleSupply, setSaleSupply] = useState(0);
    const [liquidity, setLiquidity] = useState(0);
    const [idoStartTime, setIdoStartTime] = useState();
    const [idoEndTime, setIdoEndTime] = useState();
    const [status, setStatus] = useState();
    const [ticker, setTicker] = useState();

    useEffect(() => {
        if (!ido) {
            return;
        }
        const startDate = moment(ido.startTime).format('DD MMM YYYY, HH:mm ');
        const endDate = moment(ido.endTime).format('DD MMM YYYY, HH:mm ');
        setStatus(statusMapping[ido.status]);
        setIdoStartTime(startDate);
        setIdoEndTime(endDate);
    }, [setStatus, setIdoStartTime, setIdoEndTime, ido]);


    useEffect(() => {
        if (!ido) {
            return;
        }
        const s = (ido.hardCap * ido.rate);
        setSaleSupply(s);
        setLiquidity(ido.hardCap * ido.liquidity * ido.launchPrice);

        setTicker(ido.ticker);


    }, [setSaleSupply, setLiquidity, ido]);
    return (
        <Row className="">

            <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Ticker Symbol</p>
                    <h5> {ido && ido.ticker}</h5>
                </div>
            </Col>
            <Col xs={6} className="mb-3 text-right text-md-left">
                <div className="eco-group">
                    <p>Rate</p>
                    <h5>
                        <CountUp isCounting end={ido && ido.rate || 0} decimals={2} preserveValue={true} separator=',' /> {ticker}/BNB
                    </h5>
                </div>
            </Col>

            <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Contract Address</p>
                    <h5>{
                        ido && ido.token ?
                            <> {ido.token.slice(0, 5) + "..." + ido.token.slice(-5)}
                                <a href={ido && ido.explorerAddress} target="_blank" rel="noopener noreferrer"> <img src={ExpandIcon} className="ml-2 mr-0" alt="icon" />
                                </a>
                            </> : "TBA"}
                    </h5>
                </div>
            </Col>
            <Col xs={6} className="mb-3 text-right text-md-left">
                <div className="eco-group">
                    <p>Chain</p>
                    <h5><img src={ChainIcon} alt="icon" /> BSC (BEP-20)</h5>
                </div>
            </Col>
            <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Sale Supply</p>
                    <h5>
                        <CountUp isCounting end={saleSupply || 0} decimals={2} preserveValue={true} separator=',' /> {ticker}
                    </h5>
                </div>
            </Col>

            <Col xs={6} className="mb-3 text-right text-md-left">
                <div className="eco-group">
                    <p>Liquidity Supply</p>
                    <h5>
                        <CountUp isCounting end={liquidity || 0} decimals={2} preserveValue={true} separator=',' /> {ticker}
                    </h5>
                </div>
            </Col>

            <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Soft Cap</p>
                    <h5>
                        <CountUp isCounting end={ido && ido.softCap || 0} decimals={2} preserveValue={true} separator=',' /> BNB
                    </h5>
                </div>
            </Col>

            <Col xs={6} className="mb-3 text-right text-md-left">
                <div className="eco-group">
                    <p>Hard Cap</p>
                    <h5>
                        <CountUp isCounting end={ido && ido.hardCap || 0} decimals={2} preserveValue={true} separator=',' /> BNB
                    </h5>
                </div>
            </Col>

            <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Minimum Buy</p>
                    <h5>
                        {ido && ido.minPurchase || 0} BNB
                    </h5>
                </div>
            </Col>
            <Col xs={6} className="mb-3 text-right text-md-left">
                <div className="eco-group">
                    <p>Maximum Buy</p>
                    <h5>
                        {ido && ido.maxPurchase || 0} BNB
                    </h5>
                </div>
            </Col>


            <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>End Date</p>
                    <h5>
                        {idoEndTime}
                    </h5>
                </div>
            </Col>
            <Col xs={6} className="mb-3 text-right text-md-left">
                <div className="eco-group">
                    <p>Status</p>
                    <h5>
                        {status}
                    </h5>
                </div>
            </Col>

            <Col md={12} lg={10}>
                <p className="text-left w-80 mb-4 mt-4">
                    {ido && ido.description}
                </p>

                {ido && ido.videoUrl && <div className="embed-responsive mb-4 embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src={ido && ido.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>}
            </Col>
        </Row>
    );
}

export default IdoStats;
