import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import Ido from '../components/ido/ido';
import { useIdos } from '../state/idos';

const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};

function Idos() {
    const { isClient } = useIsClient();
    if (!isClient) { return null; }

    return (
        <Row className="mb-2 ">
            <Col md={12} className="mb-md-5 mb-4">
                <div className="reward-claim-component">
                    <Row className="mb-lg-5 mb-4 reward-header">
                        <Col md={8} lg={9}>
                            <h2 className="title-seperator">HedgePay Launchpad</h2>
                            <p>
                                Welcome to HedgePay's LaunchPad! Let our community kickstart your project! Affordable, secure, and unique
                            </p>
                        </Col>
                        <Col md={4} lg={3} className="text-center mt-md-0 mt-5 text-md-right bounty-widget d-flex">
                            <Col md={12} className="d-flex align-items-center">
                                <Button
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://k13pavxdut8.typeform.com/to/Bnqj8V68"
                                    disabled={true}
                                    className="btn text-white mt-auto mb-auto btn-block ">Create Sale</Button>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col md={12} className="mb-md-5 mb-4 d-flex justify-content-center flex-column align-items-center">
                <IdoList />
            </Col>
        </Row>
    );
}

const IdoList = () => {
    const { idos } = useIdos();
    return <Row className='w-100 '>
        {
            idos.map((ido, index) => (
                <Col key={index} md={12} lg={4} className="mb-4  vault">
                    <Ido {...ido} ></Ido>
                </Col>)
            )
        }
    </Row>;
};

export default Idos;
