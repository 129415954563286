
import { navigate } from "gatsby";
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import ChainIcon from '../../images/chain-icon.png';
import * as moment from 'moment';

const statusMapping = {
    0: "Starting Soon",
    1: "Running",
    2: "Ended",
    3: "Ended"
};

function Ido(idoInfo) {
    const [progress, setProgress] = useState(0);
    const [idoStartTime, setIdoStartTime] = useState();
    const [idoEndTime, setIdoEndTime] = useState();
    const [name, setName] = useState();
    const [status, setStatus] = useState();
    const [price, setPrice] = useState(0);
    const [capitalRaised, setCapitalRaised] = useState(0);

    useEffect(() => {

        if (!idoInfo) {
            return;
        }

        let capital = idoInfo.capital || 0;
        if (idoInfo.capitalOffset) {
            capital += idoInfo.capitalOffset;
        }
        setCapitalRaised(+capital.toFixed(2));

    }, [idoInfo, setCapitalRaised]);


    useEffect(() => {
        if (!idoInfo) {
            return;
        }

        let p = (idoInfo.capital * 100) / idoInfo.hardCap;
        const startDate = moment(idoInfo.startTime).format('DD MMM YYYY, HH:mm ');
        if (idoInfo.endTime) {
            const endDate = moment(idoInfo.endTime).format('DD MMM YYYY, HH:mm ');
            setIdoEndTime(endDate);
        }

        if (idoInfo.inversedRate) {
            setPrice(1 / idoInfo.rate);
        } else {
            setPrice(idoInfo.rate);
        }
        setStatus(statusMapping[idoInfo.status]);
        setIdoStartTime(startDate);
        setName(idoInfo.name);
        setProgress(p);
    }, [setProgress, idoInfo, setStatus, setIdoStartTime, setIdoEndTime]);
    return (
        <>
            <div className="presale-preview-component h-100">
                <Card>
                    <Card.Body>
                        <Row style={{ marginBottom: '1.25rem' }}>
                            <Col className="d-flex align-items-center">
                                <img
                                    style={{ borderRadius: '50%' }}
                                    src={`/idos/${idoInfo && idoInfo.imagePath}`}
                                    width={40}
                                    height={40}
                                />
                                <h3 className="mb-0 text-sm-left text-lg-left text-md-center text-center ml-3">{name}</h3>
                            </Col>
                            <Col className="d-flex align-items-center justify-content-end">
                                <Badge pill variant={'primary'} >
                                    <span className={status === 'Running' ? "text-primary-gradient" : ""}>
                                        {status}
                                    </span>
                                </Badge>
                            </Col>
                        </Row>

                        <div>
                            <Row noGutters>
                                <Col xs={6} lg={3} className="presale-stats-group text-left">
                                    <small className="presale-stats-value-label">Chain</small>
                                    <p className="presale-stats-value-item"><img src={ChainIcon} alt="icon" /> BSC</p>
                                </Col>
                                <Col xs={6} lg={9} className="presale-stats-group text-right">
                                    <small className="presale-stats-value-label">Rate</small>
                                    <p className="presale-stats-value-item">
                                        <CountUp isCounting end={price} decimals={price > 1 ? 0 : 4} preserveValue={true} separator=',' /> {idoInfo && idoInfo.tokenSymbol} / {idoInfo && idoInfo.baseSymbol}
                                    </p>
                                </Col>
                            </Row>

                            <Row noGutters>
                                <Col sm={12} className="presale-stats-group text-left">
                                    <div className="d-flex justify-content-between">
                                        <small>Progress</small>
                                        <small>{capitalRaised} BNB</small>
                                    </div>
                                    <div className="progress" style={{ borderRadius: '0.25rem', }}>
                                        <div className="progress-bar progress-bar-success " style={{ width: progress + "%", borderRadius: '0.25rem' }}></div>
                                    </div>
                                </Col>
                            </Row>

                            <Row noGutters>
                                <Col className="presale-stats-group text-left">
                                    <small className="presale-stats-value-label">Soft Cap</small>
                                    <p className="presale-stats-value-item">
                                        {idoInfo && idoInfo.softCap ? <><CountUp isCounting end={idoInfo && idoInfo.softCap || 0} decimals={2} preserveValue={true} separator=',' /> BNB </> : "None"}
                                    </p>
                                </Col>

                                <Col className="presale-stats-group text-right">
                                    <small className="presale-stats-value-label">Hard Cap</small>
                                    <p className="presale-stats-value-item">
                                        {idoInfo && idoInfo.hardCap ? <><CountUp isCounting end={idoInfo && idoInfo.hardCap || 0} decimals={2} preserveValue={true} separator=',' /> BNB </> : "None"}
                                    </p>
                                </Col>
                            </Row>

                            <Row noGutters>
                                {status && <>
                                    <Col className="presale-stats-group text-left">
                                        <small className="presale-stats-value-label">Start Date</small>
                                        <p className="presale-stats-value-item">
                                            {idoStartTime}
                                        </p>
                                    </Col>
                                    {idoEndTime && <Col className="presale-stats-group text-md-right">
                                        <small className="presale-stats-value-label">End Date</small>
                                        <p className="presale-stats-value-item">
                                            {idoEndTime}
                                        </p>
                                    </Col>}
                                </>}
                            </Row>

                            <Row className="justify-content-between align-items-center mt-2">
                                <Col sm={12} className="mb-sm-0 mb-3">
                                    <Button onClick={
                                        () => {
                                            navigate("/ido/" + idoInfo && idoInfo.location);
                                        }}
                                        // disabled={!connected || !isBsc || !!pendingTransaction}
                                        className="text-white" block>View Sale</Button>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Card >
            </div>
        </>
    );
}

export default Ido;
