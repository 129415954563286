import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useCurrentIdo, useIsUserWhiteListed, useUserInvestment } from '../../../state/idos';
import AutoNumeric from 'autonumeric';
import NetworkContext from '../../../context/network-context';
import { getBalance } from '../../../web3/account';
import BigNumber from 'bignumber.js';
import { useIdoActions } from '../../../web3/idos';
import CoinSelect from '../../coin-select';

function IdoSwap({ address }) {

    const { ido } = useCurrentIdo(address);
    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const { investedAmount } = useUserInvestment(address);
    const { isWhitelisted } = useIsUserWhiteListed(address);

    const [minBuy, setMinBuy] = useState(0);
    const [maxBuy, setMaxBuy] = useState(0);
    const [capital, setCapital] = useState(0);
    const [hardCap, setHardCap] = useState(0);
    const [rate, setRate] = useState(0);
    const [bnbBalance, setBNBBalance] = useState(0);
    const [hpayReceive, setHpayReceive] = useState(0);
    const [errorMessage, setErrorMessage] = useState();
    const [isValid, setIsValid] = useState(false);
    const { buy } = useIdoActions(address, account);

    const baseInput = useRef(null);
    const [numericHandle, setNumericHandle] = useState(null);

    useEffect(() => {
        if (!ido) {
            return;
        }
        setMinBuy(ido.minPurchase);
        setMaxBuy(ido.maxPurchase);
        setCapital(ido.capital);
        setHardCap(ido.hardCap);
        setRate(ido.rate);
    }, [setMinBuy, setMaxBuy, setCapital, setRate, ido]);

    useEffect(() => {
        if (!account || !connected) {
            return;
        }
        const initialize = async () => {
            const balancebnb = await getBalance('BNB', account);
            setBNBBalance(balancebnb || 0);

            checkValid();
        };
        initialize();

    }, [account, connected, isWhitelisted]);

    const invest = async () => {
        const value = numericHandle.getNumber().toFixed(8);
        const tx = async () => await buy(value);

        await executeTransaction({
            message: 'Executing Swap',
            tx
        }).then(async () => {
            const balancebnb = await getBalance('BNB', account);
            setBNBBalance(balancebnb || 0);
        });
    };


    const checkValid = useCallback(() => {
        const baseValue = numericHandle.getNumber();
        const capitalValue = +capital.toFixed(8);

        if (bnbBalance < 0.01 || (bnbBalance - baseValue < 0.01)) {
            setErrorMessage(`Not enough balance left for gas.`);
            return false;
        }

        if (!isWhitelisted) {
            setErrorMessage(`Your address is not in our whitelist`);
            return false;
        }

        if (capitalValue > 0 && +capitalValue >= +hardCap) {
            setErrorMessage(`Hardcap was reached`);
            return false;
        }
        if (capitalValue + baseValue > hardCap) {
            setErrorMessage(`Only ${(hardCap - capitalValue).toLocaleString()} BNB left to invest.`);
            return false;
        }

        if (investedAmount + baseValue < +minBuy && baseValue > 0) {
            setErrorMessage(`Minimum investment is ${minBuy} BNB`);
            return false;
        }

        if (investedAmount + baseValue > +maxBuy) {
            setErrorMessage(`Maximum investment is ${maxBuy} BNB`);
            return false;
        }

        if (baseValue > bnbBalance) {
            setErrorMessage(`Insufficent balance`);
            return false;
        }

        setErrorMessage(null);
        return !!numericHandle.getNumber() > 0;
    }, [numericHandle, bnbBalance, minBuy, maxBuy, capital, hardCap, rate, investedAmount, isWhitelisted]);

    useEffect(() => {
        const handle = new AutoNumeric(baseInput.current, {
            decimalPlaces: 8,
            minimumValue: 0,
            allowDecimalPadding: false,
            emptyInputBehavior: 0,
            decimalCharacterAlternative: ','
        });
        setNumericHandle(handle);
    }, [baseInput]);


    const updateInput = useCallback(() => {
        const value = numericHandle.getNumber();
        const relativeValue = new BigNumber(value).times(rate).toFixed(4, 5);
        setHpayReceive(relativeValue);

        setIsValid(checkValid());
    }, [checkValid, numericHandle]);

    return (
        <>
            <Card className="donate-component">
                <Card.Body>
                    <Row className="align-items-center">
                        <Col md={12} className="text-lg-left mb-3">
                            <h3 className="mb-0 text-white text-center text-lg-left">Invest</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form>
                                <Form.Row className="justify-content-center no-gutters">

                                    <Form.Group as={Col} md="12" className="staking-control" controlId="validationFormik01">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label className="balance-label">
                                                Receiving {
                                                    <><NumberFormat value={hpayReceive} decimalScale={hpayReceive > 1 ? 0 : 4} displayType={'text'} thousandSeparator={true} /> {ido && ido.ticker}</>
                                                }
                                            </Form.Label>
                                            <Form.Label className="text-right balance-label">
                                                Balance <NumberFormat value={bnbBalance} displayType={'text'} thousandSeparator={true} />
                                            </Form.Label>
                                        </div>
                                        <InputGroup className="mb-0">
                                            <Form.Control
                                                className="staking-input mb-0"
                                                type="text"
                                                autoComplete="off"
                                                inputMode="decimal"
                                                name="baseInput"
                                                ref={baseInput}
                                                onChange={updateInput}
                                            />
                                            <InputGroup.Append className="staking-input-append mb-0">
                                                BNB
                                                {/* <CoinSelect 
                                                    defaultSelected={'BNB'}
                                                    onSelect={handleCoinSelect('base')}></CoinSelect> */}
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" className="mt-4 px-0 mb-0 text-center">
                                        <Button disabled={!connected || !isValid || !isBsc || !!pendingTransaction}
                                            onClick={invest} className="mb-2" block>Invest</Button>
                                        {connected && isBsc && errorMessage && <p className="mb-0">{errorMessage}</p>}

                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Col>

                    </Row>
                </Card.Body>
            </Card >
        </>
    );
}

export default IdoSwap;
