import { Link, useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import BasicIdo from "../components/ido/basic/ido-details";
import ProxyFundedIdo from "../components/ido/proxy-funded/ido-details";
import Idos from "hpay/content/Idos.json";


function IdoContainer() {
    const params = useParams();
    const [ido, setIdo] = useState();

    useEffect(() => {
        setIdo(Idos[params.idoAddress]);
    }, [params]);

    return <>
        {
            ido && ido.type === 'BASIC' && <BasicIdo></BasicIdo>
        }

        {
            ido && ido.type === 'PROXY_FUNDED' && <ProxyFundedIdo></ProxyFundedIdo>
        }
    </>;
}

export default IdoContainer;
