import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import NetworkContext from '../../../context/network-context';
import { useCurrentIdo, useUserBalanaceAmount, useUserInvestment } from '../../../state/proxy-funded-ido';
import { useIdoActions } from '../../../web3/proxy-funded-ido';

function IdoClaim({ address }) {
    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const { ido } = useCurrentIdo(address);
    const { investedAmount } = useUserInvestment(address);
    const { userBalance } = useUserBalanaceAmount(address);

    const [claimed, setClaimed] = useState(false);

    const { claim, refund } = useIdoActions(address, account);

    const handleClaim = async () => {
        if (ido) {
            window.open(ido.claimLink, '_newtab');
        }
    };

    const handleRefund = async () => {
        const tx = async () => refund();

        await executeTransaction({
            message: 'Refunding Investment',
            tx
        }).then(() => {
            setClaimed(true);
        });
    };

    useEffect(() => {
        const claimed = userBalance === 0;
        setClaimed(claimed);
    }, [investedAmount, userBalance]);

    return (
        <>
            <Card className="donate-component">
                <Card.Body>
                    <Row style={{ marginBottom: '1.5rem' }} className="align-items-center">
                        <Col md={12} className="text-lg-left">
                            <h3 className="mb-0 text-white text-center text-lg-left">Presale Ended</h3>
                        </Col>
                    </Row>
                    {ido && ido.status === 2 &&
                        <>
                            <Col md={12}>

                                <p className='text-justified mb-4'> This presale has ended. You will be able to claim your tokens once the owner finalizes this funding phase. </p>

                            </Col>
                            <Col md={12}>
                                <Button
                                    disabled={!connected || !isBsc}
                                    onClick={handleClaim}
                                    className="mb-2" block>Claim</Button>
                            </Col>

                        </>
                    }
                    {
                        ido && ido.status === 3 &&
                        <Row>
                            <p className='text-center mb-4'>This IDO has did not raise the minumum amount of funds in order to get validated. You may refund your investment. </p>
                            <Col md={12}>
                                <Button
                                    onClick={handleRefund}
                                    disabled={!connected || !isBsc || !!pendingTransaction || claimed}
                                    className="mb-2" block>Refund</Button>
                            </Col>
                        </Row>
                    }

                </Card.Body>
            </Card >
        </>
    );
}

export default IdoClaim;
