import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import NetworkContext from '../../../../context/network-context';
import { useApprove } from '../../../../web3/account';
import { useIdoAdminActions } from '../../../../web3/idos';
import Whitelist from './whitelist';

function AdminPanel({ token, address, ...ido }) {
    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const [openForAll, setOpenForAll] = useState();
    const { setOpenForAll: toggleOpen, fundPresale, finalizePresale } = useIdoAdminActions(address, account);
    const { approve, isApproved } = useApprove(token, account, address);
    const [supply, setSupply] = useState();

    useEffect(() => {
        if (!ido) {
            return;
        }
        setOpenForAll(ido.openForAll);
        const supply = ido.hardCap * ido.rate + (ido.hardCap * ido.liquidity * ido.launchPrice);
        setSupply(supply);
    }, [ido, isApproved]);


    const toggleOpenForAll = async () => {
        const tx = async () => toggleOpen(!openForAll);

        await executeTransaction({
            message: 'Processing',
            tx
        });
    };

    const handleApprove = async () => {
        const tx = async () => approve();

        await executeTransaction({
            message: 'Approving',
            tx
        });
    };

    const handleFund = async () => {
        const tx = async () => fundPresale();

        await executeTransaction({
            message: 'Sending Funds',
            tx
        });
    };

    const handleFinalize = async () => {
        const tx = async () => finalizePresale();

        await executeTransaction({
            message: 'Ending Presale',
            tx
        });
    };


    return (<>
        <Row>
            <Col md={6}>
                <h1>IDO Manager</h1>
            </Col>
            <Col md={6}>
                <p className="banner-info">
                    Please ensure that the presale address <strong className='text-primary-gradient'> {address} </strong>  is excluded from any transaction mechanics such as fees, rewards or reflections and that it can freely accept the presale supply.
                </p>
            </Col>
        </Row>
        <Row className='mt-4'>
            <Col md={6}>
                <Whitelist></Whitelist>
            </Col>

            <Col md={3}>
                <h3>Restrictions</h3>
                <p>{openForAll ? "Open For All" : "Only whitelisted addresses can buy"}</p>
                <Button onClick={toggleOpenForAll} disabled={!connected || !isBsc || !!pendingTransaction}
                    className="btn text-white">{openForAll ? "Restrict" : "Open For All"}</Button>

                <h3 className='mt-4'>Funding Status</h3>
                {isApproved ? <>
                    <p>You need <strong> <NumberFormat value={supply} displayType={'text'} thousandSeparator={true} /> {ido.ticker} </strong></p>
                    <Button onClick={handleFund} disabled={!connected || !isBsc || !!pendingTransaction} className="btn text-white">Fund Presale</Button>
                </> : <>
                    <p>Please Fund The presale</p>
                    <Button onClick={handleApprove} disabled={!connected || !isBsc || !!pendingTransaction}
                        className="btn text-white">Approve</Button>
                </>
                }
            </Col>

            <Col md={3}>
                <h3>Finalize</h3>
                <p>Presale can be finalized only after hardcap is reached or when the soft cap is rasied and the timer expires </p>

                <Button onClick={handleFinalize} disabled={ido.status !== 2 || !connected || !isBsc || !!pendingTransaction}
                    className="btn text-white">Finalize</Button>
            </Col>
        </Row>
    </>);
}

export default AdminPanel;
