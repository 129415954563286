import { Link, useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import Countdown from 'react-countdown';
import CountUp from 'react-countup';
import AdminPanel from "./admin/admin-panel";
import IdoClaim from "./ido-claim";
import IdoHeader from "./ido-header";
import IdoStats from "./ido-stats";
import IdoSwap from "./ido-swap";
import { useCurrentIdo, useRefreshIdo, useUserInvestment, useUserIsAdmin } from '../../../state/idos';
import { useIdoAddress } from '../../../utils/utils';
import { useInterval } from 'react-use';


const Progress = ({ investedAmount, receiving, ido, progress }) => <><Row className="mb-2">
    <Col xs={4}>
        <div className="eco-group">
            <p>Invested</p>
            <h5>
                <CountUp isCounting end={investedAmount || 0} decimals={2} preserveValue={true} separator=',' /> BNB
            </h5>
        </div>
    </Col>
    <Col xs={8} className="text-right">
        <div className="eco-group">
            <p>Tokens</p>
            <h5>
                <CountUp isCounting end={receiving} decimals={2} preserveValue={true} separator=',' /> {ido.ticker}
            </h5>
        </div>
    </Col>
</Row>


    <div className="position-relative" >
        <div className="d-flex justify-content-between">
            <small>Progress</small>
            <small>{ido && ido.capital} BNB</small>
        </div>
        <div className="progress" style={{ borderRadius: '0.25rem', }}>

            <div className="progress-bar progress-bar-success " style={{ width: progress + "%", borderRadius: '0.25rem' }}></div>

        </div>
    </div>
</>;

function IdoPad() {
    const idoAddress = useIdoAddress();
    const { ido } = useCurrentIdo(idoAddress);
    const [_, refreshIdo] = useRefreshIdo();
    const [progress, setProgress] = useState(0);
    const [receiving, setReceiving] = useState(0);
    const [startDate, setStartDate] = useState();
    const [status, setStatus] = useState(0);

    const { investedAmount } = useUserInvestment(idoAddress);
    const { isAdmin } = useUserIsAdmin(idoAddress);

    useEffect(() => {
        if (!ido) {
            return;
        }

        let p = (ido.capital * 100) / ido.hardCap;
        setStartDate(ido.startTime);

        setReceiving(investedAmount * ido.rate);
        setProgress(p);
        setStatus(ido.status);

    }, [setProgress, ido, setReceiving, investedAmount, setStartDate]);

    useInterval(() => {
        refreshIdo(idoAddress);
    }, 5000);

    const handleComplete = async () => {
        refreshIdo(idoAddress);
    };

    return (<>
        <Row className="mb-2 ">
            <Col md={12} className="mb-4 m-auto">
                <IdoHeader idoInfo={ido} address={idoAddress} ></IdoHeader>
            </Col>
        </Row >
        <Row className=" flex-wrap-reverse mb-2 ">
            <Col md={7} className="mb-4">
                <IdoStats address={idoAddress} ></IdoStats>
            </Col>

            <Col md={5} className="mb-4">
                <Row className=" flex-md-wrap flex-wrap-reverse">

                    <Col className="text-left mb-md-4 ">
                        {
                            status < 2 ? <IdoSwap address={idoAddress} ></IdoSwap> : <IdoClaim address={idoAddress} ></IdoClaim>
                        }
                    </Col>

                    <Col xs={12} className="text-left mb-4 mb-md-0">
                        {ido && <div className="banner-info">
                            {status === 0 ?
                                <div className="text-center">
                                    <small>Starts In</small>
                                    <h3 className="text-primary-gradient text-center m-0">
                                        <Countdown onComplete={handleComplete} key={startDate} date={startDate}></Countdown>
                                    </h3>
                                </div> :
                                <Progress investedAmount={investedAmount} receiving={receiving} ido={ido} progress={progress}></Progress>
                            }
                            {/* {investedAmount > 0 && status === 1 && <Col xs={12} className="text-left mb-4 mb-md-0">
                                <Button >Emergency Withdraw</Button>
                            </Col>} */}
                        </div>}
                    </Col>
                    <Col xs={12} className="text-left mb-4 mb-md-0 mt-3">
                        <p>  * <Link target='_blank' to="/rewards/">
                            <span className='text-primary-gradient font-weight-bold'> Stake  </span>
                        </Link>  at least 20,000 HPAY to earn exclusive whitelist access to H-PAD idos.</p>

                    </Col>

                    {ido && ido.videoUrl && (
                        <Col md={12} className="d-lg-none mb-4">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src={ido && ido.videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </Col>
                    )}
                </Row>
            </Col >
        </Row >

        {
            ido && isAdmin && <Row className="mb-2 ">
                <AdminPanel {...ido}> </AdminPanel>
            </Row>
        }

    </>
    );
}

export default IdoPad;
