import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import Countdown from 'react-countdown';
import CountUp from 'react-countup';
import NetworkContext from '../../../context/network-context';
import { useCurrentIdo, useRefreshIdo, useUserBalanaceAmount, useUserInvestment, useUserIsAdmin } from '../../../state/proxy-funded-ido';
import { useIdoAddress } from '../../../utils/utils';
import { useIdoActions } from '../../../web3/proxy-funded-ido';
import AdminPanel from "./admin/admin-panel";
import IdoClaim from "./ido-claim";
import IdoHeader from "./ido-header";
import IdoStats from "./ido-stats";
import IdoSwap from "./ido-swap";
import IdoVesting from "./ido-vesting";


const Progress = ({ investedAmount, receiving, ido, progress }) => {
    const [capitalRaised, setCapitalRaised] = useState(0);

    useEffect(() => {

        if (!ido) {
            return;
        }

        let capital = ido.capital;
        if (ido.capitalOffset) {
            capital += ido.capitalOffset;
        }
        setCapitalRaised(capital);

    }, [ido, setCapitalRaised]);

    return <><Row className="mb-2">

        <Col xs={5}>
            <div className="eco-group">
                <p>Invested</p>
                <h5>
                    <CountUp isCounting end={investedAmount || 0} decimals={2} preserveValue={true} separator=',' /> BNB
                </h5>
            </div>
        </Col>
        <Col xs={7} className="text-right">
            <div className="eco-group">
                <p>Tokens</p>
                <h5>
                    <CountUp isCounting end={receiving} decimals={2} preserveValue={true} separator=',' /> {ido.ticker}
                </h5>
            </div>
        </Col>
    </Row>


        <div className="position-relative" >
            <div className="d-flex justify-content-between">
                <small>Progress</small>
                <small><CountUp isCounting end={capitalRaised} decimals={2} preserveValue={true} separator=',' />  BNB</small>
            </div>
            <div className="progress" style={{ borderRadius: '0.25rem', }}>
                <div className="progress-bar progress-bar-success " style={{ width: progress + "%", borderRadius: '0.25rem' }}></div>
            </div>
        </div>
    </>;
};

function IdoPad() {

    const { account, pendingTransaction, executeTransaction } = useContext(NetworkContext);

    const idoAddress = useIdoAddress();
    const { ido } = useCurrentIdo(idoAddress);
    const [, refreshIdo] = useRefreshIdo();
    const [progress, setProgress] = useState(0);
    const [receiving, setReceiving] = useState(0);

    const [startDate, setStartDate] = useState();
    const [status, setStatus] = useState(0);
    const [showConfirm, setShowConfirm] = useState(false);


    const { investedAmount } = useUserInvestment(idoAddress);
    const { emergencyWithdraw } = useIdoActions(idoAddress, account);
    const { userBalance } = useUserBalanaceAmount(idoAddress);
    const { isAdmin } = useUserIsAdmin(idoAddress);

    useEffect(() => {
        if (!ido) {
            return;
        }
        let p = (ido.capital * 100) / ido.hardCap;
        setStartDate(ido.startTime);
        setReceiving(userBalance);
        setProgress(p);
        setStatus(ido.status);

    }, [setProgress, ido, setReceiving, investedAmount, setStartDate, userBalance, isAdmin]);

    const handleComplete = async () => {
        refreshIdo(idoAddress);
    };

    const handleWithdraw = async () => {
        const tx = async () => await emergencyWithdraw();
        try {
            await executeTransaction({
                message: 'Executing Transaction',
                tx
            });
        } catch (error) {
            console.log(error);
        }
        setShowConfirm(false);
    };

    return (<>
        <Row className="mb-2 ">
            <Col md={12} className="mb-4 m-auto">
                <IdoHeader idoInfo={ido} ></IdoHeader>
            </Col>
        </Row >
        <Row className=" flex-wrap-reverse mb-2 ">
            <Col md={7} className="mb-4">
                <IdoStats address={idoAddress} ></IdoStats>
                {ido && ido.vesting && <IdoVesting address={idoAddress} ></IdoVesting>}

            </Col>

            <Col md={5} className="mb-4">
                <Row className=" flex-md-wrap flex-wrap-reverse">

                    <Col className="text-left mb-md-4 ">
                        {
                            status < 2 ? <IdoSwap address={idoAddress} ></IdoSwap> : <IdoClaim address={idoAddress} ></IdoClaim>
                        }
                    </Col>

                    <Col xs={12} className="text-left mb-4 mb-md-0">
                        {ido && <div className="banner-info">
                            {status === 0 ?
                                <div className="text-center">
                                    <small>Starts In</small>
                                    <h3 className="text-primary-gradient text-center m-0">
                                        <Countdown onComplete={handleComplete} key={startDate} date={startDate}></Countdown>
                                    </h3>
                                </div> :
                                <Progress investedAmount={investedAmount} receiving={receiving} ido={ido} progress={progress}></Progress>
                            }
                            {investedAmount > 0 && status === 1 && <div className="text-center mt-2 mb-md-0">
                                <Button
                                    disabled={pendingTransaction}
                                    onClick={() => setShowConfirm(true)} variant='link' className="p-0"><span>
                                        <small>
                                            Emergency Withdraw
                                        </small>
                                    </span></Button>
                            </div>}
                        </div>}
                    </Col>

                    {ido && ido.videoUrl && (
                        <Col md={12} className="d-lg-none mb-4">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src={ido && ido.videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </Col>
                    )}
                </Row>
            </Col >
        </Row >

        {
            ido && isAdmin && <Row className="mb-2 ">
                <AdminPanel {...ido}> </AdminPanel>
            </Row>
        }

        <Modal
            className="stake-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={!!showConfirm} onHide={() => {
                if (!pendingTransaction) {
                    setShowConfirm(false);
                }
            }} >

            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Row style={{ marginBottom: '1rem' }} noGutters>
                            <Col md={12} className=" text-center ">
                                <h3 className="text-white ">Emergency Withdraw</h3>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }} noGutters>
                            <Col md={12} >
                                <p style={{ fontSize: '1rem', fontWeight: 600, textAlign: 'center' }}> <span className='text-primary-gradient'>Tax Warning: </span>A 10% tax is deducted if you withdraw before the end of the sale. The refund will be issued in the form of BUSD tokens.</p>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }} noGutters>
                            <Col md={12} className=" text-center ">
                                <Button onClick={handleWithdraw} >
                                    Withdraw
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    </>
    );
}

export default IdoPad;
