import * as moment from "moment";
import React from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';


function RateSchedule({ schedule, handleClose, ticker, info }) {

    return (
        <div className="vesting-schedule">
            <Card>
                <Card.Body>
                    <Row style={{ marginBottom: '1rem' }} noGutters>
                        <Col md={12} className=" text-center ">
                            <h3 className="text-white ">Rate Schedule</h3>
                            <small>{info}</small>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '1rem' }} className="justify-content-center" noGutters>
                        <Col xs={6}>
                            <small>Date</small>

                        </Col>
                        <Col xs={6} className="text-right">
                            <small>Rate</small>

                        </Col>
                    </Row>
                    {
                        schedule.map((item, index) => <Row key={index} className="justify-content-center" noGutters>
                            <Col xs={6}>
                                <p className="font-weight-normal">{moment(item.date).format('DD MMM YYYY')}</p>
                            </Col>
                            <Col xs={6} className="text-right">
                                <p className="text-primary-gradient font-weight-bold">{item.rate} {ticker}</p>
                            </Col>
                        </Row>)
                    }
                    <Button block onClick={handleClose} className="mt-4">Close</Button>
                </Card.Body >
            </Card >
        </div >
    );
}

export default RateSchedule;
