
import React from 'react';
import { Badge, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Facebook from '../../../images/inline/facebook.inline.svg';
import Medium from '../../../images/inline/medium.inline.svg';
import Reddit from '../../../images/inline/reddit.inline.svg';

import Telegram from '../../../images/inline/telegram-icon.inline.svg';
import Twitter from '../../../images/inline/twitter.inline.svg';
import ExpandIcon from '../../../images/expand-icon.png';


function IdoHeader({ idoInfo }) {
    const SocialMedia = <div >
        {idoInfo && idoInfo.telegram && <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-telegram`}>
                    <strong>Telegram</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={idoInfo && idoInfo.telegram}>
                <Telegram className="footer-icon" />
            </a>
        </OverlayTrigger>}

        {idoInfo && idoInfo.twitter && <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-twitter`}>
                    <strong>Twitter</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={idoInfo && idoInfo.twitter}>
                <Twitter className="footer-icon" />
            </a>
        </OverlayTrigger>}

        {idoInfo && idoInfo.reddit && <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-twitter`}>
                    <strong>Reddit</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={idoInfo && idoInfo.reddit}>
                <Reddit className="footer-icon" />
            </a>
        </OverlayTrigger>}


        {idoInfo && idoInfo.medium && <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-Whatsapp`}>
                    <strong>Medium</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={idoInfo && idoInfo.medium}>
                <Medium className="footer-icon" />
            </a>
        </OverlayTrigger>}

        {idoInfo && idoInfo.facebook && <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-Whatsapp`}>
                    <strong>Facebook</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={idoInfo && idoInfo.facebook}>
                <Facebook className="footer-icon" />
            </a>
        </OverlayTrigger>}
    </div>;


    return (
        <div className="reward-claim-component">
            <Row className="mb-4 reward-header">
                <Col md={8} lg={9} className="d-flex align-items-center">
                    <div className="title-seperator d-flex align-items-center">
                        <img
                            style={{ borderRadius: '50%' }}
                            src={`/idos/${idoInfo && idoInfo.imagePath}`}
                            width={75}
                            height={75}
                        />

                        <h2 className='ml-4 mb-0 title-seperator' >{idoInfo && idoInfo.name} Sale</h2>

                        {idoInfo && idoInfo.kyc && <OverlayTrigger
                            overlay={
                                <Tooltip id={`tooltip-Whatsapp`}>
                                    <strong>
                                        The team members of this project have provided legal documentaion stating their real life identity.
                                    </strong>
                                </Tooltip>
                            }>
                            <Badge pill variant={'primary'} className='align-self-start ml-3' >
                                <span className="text-primary-gradient align-self-start" >
                                    KYC
                                </span>
                            </Badge>
                        </OverlayTrigger>
                        }

                        {idoInfo && idoInfo.kyc && <OverlayTrigger
                            overlay={
                                <Tooltip id={`tooltip-Whatsapp`}>
                                    <strong>
                                        The code for this contract has been audited by the HedgePay development team.
                                    </strong>
                                </Tooltip>
                            }>
                            <Badge pill variant={'primary'} className='align-self-start ml-3' >
                                <span className="text-primary-gradient align-self-start" >
                                    Audit
                                </span>
                            </Badge>
                        </OverlayTrigger>
                        }
                    </div>
                </Col>
                <Col md={4} lg={3} className="d-flex flex-column align-items-end">

                    <p className='mb-3 mr-2'>
                        <a target="_blank" rel="noopener noreferrer"
                            href={idoInfo && idoInfo.website}>
                            Visit Website
                            <img src={ExpandIcon} width={16} className="ml-2 mr-0" alt="icon" />
                        </a>
                    </p>
                    {SocialMedia}
                </Col>
            </Row>
        </div>
    );
}

export default IdoHeader;
