import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useCurrentIdo, useIsUserWhiteListed, useUserInvestment } from '../../../state/proxy-funded-ido';
import AutoNumeric from 'autonumeric';
import NetworkContext from '../../../context/network-context';
import { getBalance, useApprove } from '../../../web3/account';
import BigNumber from 'bignumber.js';
import { useIdoActions } from '../../../web3/proxy-funded-ido';
import CoinSelect from '../../coin-select';
import IdoConfirm from "./ido-confirm";
import { Tokens } from '../../../web3/contracts/contracts';
import { useBnbPrice } from '../../../web3/price-data';

function IdoSwap({ address }) {

    const [minBuy, setMinBuy] = useState(0);
    const [base, setBase] = useState('BNB');

    const { ido } = useCurrentIdo(address);
    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const { approve, checkApproved } = useApprove(Tokens[base].address, account, address);
    const { isWhitelisted } = useIsUserWhiteListed(address);

    const { investedAmount } = useUserInvestment(address);
    const bnbPrice = useBnbPrice();

    const [checked, setChecked] = useState(false);

    const [isApproved, setIsApproved] = useState(true);

    const [maxBuy, setMaxBuy] = useState(0);
    const [capital, setCapital] = useState(0);
    const [hardCap, setHardCap] = useState(0);
    const [rate, setRate] = useState(0);
    const [bnbValue, setBnbValue] = useState();

    const [modalStatus, setModalStatus] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const handleClose = () => {
        if (!pendingTransaction) {
            setShowConfirm(false);
        }
    };

    const [bnbBalance, setBNBBalance] = useState(0);
    const [busdBalance, setBusdBalance] = useState(0);

    const [hpayReceive, setHpayReceive] = useState(0);
    const [errorMessage, setErrorMessage] = useState();
    const [isValid, setIsValid] = useState(false);
    const { buy, buyWithBusd } = useIdoActions(address, account);

    const baseInput = useRef(null);
    const [numericHandle, setNumericHandle] = useState(null);

    useEffect(() => {
        if (!ido) {
            return;
        }
        setMinBuy(ido.minPurchase);
        setMaxBuy(ido.maxPurchase);
        setCapital(ido.capital);
        setHardCap(ido.hardCap);
        setRate(ido.rate);
    }, [setMinBuy, setMaxBuy, setCapital, setRate, ido]);

    useEffect(() => {
        if (!account || !connected) {
            return;
        }
        const initialize = async () => {
            const balancebnb = await getBalance('BNB', account);
            setBNBBalance(balancebnb || 0);

            const balancebusd = await getBalance('BUSD', account);
            setBusdBalance(balancebusd || 0);

            setIsValid(await checkValid());
        };
        initialize();

    }, [account, connected, isWhitelisted, checkApproved, checkValid]);

    const swap = async () => {
        let tx;
        let baseValue = numericHandle.getNumber().toFixed(8);
        const baseToken = Tokens[base];
        const quoteToken = ido.tokenSymbol;

        const bnbValue = baseValue;
        if (base === 'BNB') {
            baseValue = new BigNumber(baseValue).times(bnbPrice).toFixed(8);
        }
        const price = ido.inversedRate ? 1 / rate : rate;
        const quoteValue = new BigNumber(baseValue).div(price).toFixed(8);

        const config = {
            quoteValue,
            bnbValue,
            baseValue,
            base: baseToken,
            quote: quoteToken,
            price,
            quoteTokenIcon: ido.imagePath,
        };
        if (base === 'BNB') {
            setModalStatus({
                ...config,
                mode: 'basic',
                action: async (minAmount) => {
                    tx = async () => await buy(bnbValue, minAmount);
                    try {
                        await executeTransaction({
                            message: 'Executing Buy',
                            tx
                        });
                    } catch (error) {
                        console.log(error);
                    }
                    setShowConfirm(false);
                }
            });
        } else {
            setModalStatus({
                ...config,
                mode: 'hop',
                action: async (minAmount) => {
                    tx = async () => await buyWithBusd(baseValue, minAmount);
                    try {
                        await executeTransaction({
                            message: 'Executing Buy',
                            tx
                        });
                    } catch (error) {
                        console.log(error);
                    }
                    setShowConfirm(false);
                }
            });
        }
        setShowConfirm(true);
    };


    const checkValid = useCallback(async () => {
        if (!numericHandle) {
            return;
        }
        let baseValue = numericHandle.getNumber();
        const capitalValue = +capital.toFixed(8);

        const approved = await checkApproved(baseValue);
        setIsApproved(approved);
        if (!approved) {
            return;
        }

        if (base === 'BUSD') {
            if (baseValue > busdBalance) {
                setErrorMessage(`Insufficent balance`);
                return false;
            }

            baseValue = +(new BigNumber(baseValue).div(bnbPrice).toFixed());
        } else {
            if (baseValue > bnbBalance) {
                setErrorMessage(`Insufficent balance`);
                return false;
            }
            if (bnbBalance - baseValue < 0.01) {
                setErrorMessage(`Not enough balance left for gas.`);
                return false;
            }
        }

        if (bnbBalance < 0.01) {
            setErrorMessage(`Not enough balance left for gas.`);
            return false;
        }

        if (capitalValue > 0 && +capitalValue >= +hardCap) {
            setErrorMessage(`Hardcap was reached`);
            return false;
        }
        if (capitalValue + baseValue > hardCap) {
            setErrorMessage(`Only ${(hardCap - capitalValue).toLocaleString()} BNB left to invest.`);
            return false;
        }

        if (investedAmount + baseValue < +minBuy && baseValue > 0) {
            setErrorMessage(`Minimum investment is ${minBuy} BNB`);
            return false;
        }

        if (investedAmount + baseValue > +maxBuy) {
            setErrorMessage(`Maximum investment is ${maxBuy} BNB`);
            return false;
        }

        if (!isWhitelisted) {
            setErrorMessage(`Your address is not in our whitelist`);
            return false;
        }

        setErrorMessage(null);
        return !!numericHandle.getNumber() > 0;
    }, [numericHandle, bnbBalance, bnbPrice, busdBalance, minBuy, maxBuy, capital, hardCap, rate, checkApproved, investedAmount, base, isWhitelisted]);


    useEffect(() => {
        const handle = new AutoNumeric(baseInput.current, {
            decimalPlaces: 8,
            minimumValue: 0,
            allowDecimalPadding: false,
            emptyInputBehavior: 0,
            decimalCharacterAlternative: ','
        });

        setNumericHandle(handle);

    }, [baseInput]);


    const handleCoinSelect = useCallback(async (coin) => {
        setBase(coin);
        setIsValid(await checkValid());
    }, [setBase, checkValid, setIsValid]);


    const updateInput = useCallback(async () => {
        const value = numericHandle.getNumber();
        let relativeValue;
        if (base === 'BNB') {
            relativeValue = new BigNumber(value).times(bnbPrice).times(rate).toFixed(2);
            setBnbValue(null);
        } else {
            relativeValue = new BigNumber(value).times(rate).toFixed(2);
            setBnbValue(new BigNumber(value).div(bnbPrice).toFixed(4));
        }

        setHpayReceive(relativeValue);

        setIsValid(await checkValid());
    }, [checkValid, numericHandle, base, bnbPrice, setBnbValue]);

    const handleApprove = useCallback(async () => {
        let baseValue = numericHandle.getNumber();

        const tx = async () => await approve(baseValue);

        await executeTransaction({
            message: 'Approving',
            tx
        });

        setIsValid(await checkValid());
    }, [approve, executeTransaction, numericHandle]);

    const ActionButton = () => {
        if (isApproved) {
            return (<>
                <div className='d-flex mb-3'>
                    <Form.Check
                        type="checkbox"
                        onChange={() => {
                            setChecked(!checked);
                        }}
                        checked={checked}
                    /> <small>I agree to the terms and conditions of this launchpad and fully understand the vesting schedule.</small>
                </div>
                <Button disabled={!checked || !connected || !isValid || !isBsc || !!pendingTransaction} onClick={swap} className="mb-2" block>Invest</Button>
            </>);
        } else {
            return (<Button disabled={!connected || !isBsc || !!pendingTransaction}
                onClick={handleApprove} className="mb-2" block>Approve</Button>);
        }
    };

    return (
        <>
            <Card className="donate-component">
                <Card.Body>
                    <Row className="align-items-center">
                        <Col md={12} className="text-lg-left mb-3">
                            <h3 className="mb-0 text-white text-center text-lg-left">Invest</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form>
                                <Form.Row className="justify-content-center no-gutters">

                                    <Form.Group as={Col} md="12" className="staking-control" controlId="validationFormik01">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label className="balance-label">
                                                Receiving {
                                                    <><NumberFormat value={hpayReceive} decimalScale={hpayReceive > 1 ? 0 : 4} displayType={'text'} thousandSeparator={true} /> {ido && ido.ticker}</>
                                                }
                                            </Form.Label>
                                            <Form.Label className="text-right balance-label">
                                                Balance <NumberFormat value={base === 'BNB' ? bnbBalance : busdBalance} displayType={'text'} thousandSeparator={true} />
                                            </Form.Label>
                                        </div>
                                        <InputGroup className="mb-0">
                                            <Form.Control
                                                className="staking-input mb-0"
                                                type="text"
                                                autoComplete="off"
                                                inputMode="decimal"
                                                name="baseInput"
                                                ref={baseInput}
                                                onChange={updateInput}
                                            />
                                            <InputGroup.Append className="staking-input-append mb-0">
                                                {!!bnbValue && <span className="relative-bnb-value">{bnbValue} BNB</span>}
                                                <CoinSelect
                                                    defaultSelected={'BNB'}
                                                    onSelect={handleCoinSelect}></CoinSelect>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" className="mt-4 px-0 mb-0 text-center">

                                        <ActionButton></ActionButton>
                                        {connected && isBsc && errorMessage && <p className="mb-0">{errorMessage}</p>}
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card >

            <Modal
                className="stake-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={!!showConfirm} onHide={handleClose} >

                <Modal.Body>
                    <IdoConfirm  {...modalStatus}></IdoConfirm>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default IdoSwap;
