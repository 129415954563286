
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useCurrentIdo } from '../../../state/proxy-funded-ido';
import RateSchedule from '../../rates-schedule';
import ExpandIcon from '../../../images/expand-icon.png';

const statusMapping = {
    0: "Starting Soon",
    1: "Running",
    2: "Ended",
    3: "Ended"
};

function IdoStats({ address }) {
    const { ido } = useCurrentIdo(address);
    const [idoStartTime, setIdoStartTime] = useState();
    const [idoEndTime, setIdoEndTime] = useState();

    const [status, setStatus] = useState();
    const [ticker, setTicker] = useState();
    const [price, setPrice] = useState(0);
    const [rateSchedule, setRateSchedule] = useState();
    const [showModal, setShowModal] = useState();
    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (!ido) {
            return;
        }
        const startDate = moment(ido.startTime).format('DD MMM YYYY, HH:mm ');
        const endDate = moment(ido.endTime).format('DD MMM YYYY, HH:mm ');
        setStatus(statusMapping[ido.status]);
        setIdoStartTime(startDate);
        setIdoEndTime(endDate);
        setRateSchedule(ido.rateSchedule);
    }, [setStatus, setIdoStartTime, ido]);


    useEffect(() => {
        if (!ido) {
            return;
        }
        if (ido.inversedRate) {
            setPrice(1 / ido.rate);
        } else {
            setPrice(ido.rate);
        }
        setTicker(ido.ticker);
    }, [ido]);
    return (
        <>
            <Row className="">

                <Col xs={6} className="mb-3">
                    <div className="eco-group">
                        <p>Ticker Symbol</p>
                        <h5> {ido && ido.ticker}</h5>
                    </div>
                </Col>

                <Col xs={6} className="mb-3 text-right text-md-left">
                    <div className="eco-group">
                        <p>Status</p>
                        <h5>
                            {status}
                        </h5>
                    </div>
                </Col>

                <Col xs={6} className="mb-3 text-right text-md-left">
                    <div className="eco-group">
                        <p>Rate  {ido && ido.rateSchedule && <>( <span className='clickable text-primary-gradient'>Variable Rate <img src={ExpandIcon} width={16} onClick={() => setShowModal(true)} className="ml-2 mr-0 clickable" alt="icon" /></span> )</>} </p>
                        <h5>
                            <CountUp isCounting end={price} decimals={4} separator=',' /> {ticker}/BUSD
                        </h5>
                    </div>
                </Col>

                <Col xs={6} className="mb-3 text-right text-md-left">
                    <div className="eco-group">
                        <p>Launch Price</p>
                        <h5>
                            <CountUp isCounting end={ido && ido.launchPrice || 0} decimals={4} preserveValue={true} separator=',' /> {ticker}/BUSD
                        </h5>
                    </div>
                </Col>
                <Col xs={6} className="mb-3 text-right text-md-left">
                    <div className="eco-group">
                        <p>Soft Cap</p>
                        <h5>
                            {ido && ido.softCap ? <><CountUp isCounting end={ido && ido.softCap || 0} decimals={2} preserveValue={true} separator=',' /> BNB </> : "None"}
                        </h5>
                    </div>
                </Col>

                <Col xs={6} className="mb-3 text-right text-md-left">
                    <div className="eco-group">
                        <p>Hard Cap</p>
                        <h5>
                            {ido && ido.hardCap ? <> <CountUp isCounting end={ido && ido.hardCap || 0} decimals={2} preserveValue={true} separator=',' /> BNB</> : "None"}
                        </h5>
                    </div>
                </Col>

                <Col xs={6} className="mb-3">
                    <div className="eco-group">
                        <p>Minimum Buy</p>
                        <h5>
                            <CountUp isCounting end={ido && ido.minPurchase || 0} decimals={2} preserveValue={true} separator=',' /> BNB
                        </h5>
                    </div>
                </Col>
                <Col xs={6} className="mb-3 text-right text-md-left">
                    <div className="eco-group">
                        <p>Maximum Buy</p>
                        <h5>
                            <CountUp isCounting end={ido && ido.maxPurchase || 0} decimals={2} preserveValue={true} separator=',' /> BNB
                        </h5>
                    </div>
                </Col>

                <Col xs={6} className="mb-3">
                    <div className="eco-group">
                        <p>Start Date</p>
                        <h5>
                            {idoStartTime}
                        </h5>
                    </div>
                </Col>

                <Col xs={6} className="mb-3">
                    <div className="eco-group">
                        <p>End Date</p>
                        <h5>
                            {idoEndTime}
                        </h5>
                    </div>
                </Col>



                <Col md={12} lg={10}>
                    <p className="text-left w-80 mb-4 mt-4">
                        {ido && ido.description}
                    </p>

                    {ido && ido.videoUrl && <div className="embed-responsive mb-4 embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src={ido && ido.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>}
                </Col>
            </Row>

            <Modal
                className="stake-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={!!showModal} onHide={handleClose} >

                <Modal.Body>
                    <RateSchedule  {...rateSchedule} ticker={ticker + '/BUSD'} handleClose={handleClose}></RateSchedule>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default IdoStats;
